$(document).ready(function () {

    // Header Menu Toggle
    $('.js-menu-toggle').on('click', function () {
        $(this).toggleClass('active');
        if ($('.js-menu-toggle').hasClass('active')) {
            $('.c-nav--header').fadeIn();
        }
        else
            $('.c-nav--header').fadeOut();
    });

    // Window Resize to SP
    $(window).on('resize', function () {
        var myWindowWidth = $(window).width() + 16;

        if (myWindowWidth < 767) {
            $(window).scroll(function () {
                var scroll = $(window).scrollTop();
                if (scroll > 20) {
                    $(".js-menu-toggle").addClass("js-menu-toggle--fixed");
                }
            });

            // Nav Header Show/Hide
            $(function () {

                if ($('.js-menu-toggle').hasClass('active')) {

                    $('.c-nav--header').show();
                }
                else
                    $('.c-nav--header').hide();
            });

            $('.js-sp-slider').not('.slick-initialized').slick({
                autoplay: true,
                variableWidth: true,
                centerMode: true,
                centerPadding: 0,
            });
            $('.js-facility-sp-slider').not('.slick-initialized').slick({
                arrows: true,
                autoplay: true,
                centerMode: true,
                centerPadding: 0,
                variableWidth: true,
            });

        }
        else {
            $('.c-nav--header').show();
            if ($('.js-sp-slider,.js-facility-sp-slider').hasClass('slick-initialized')) {
                $('.js-sp-slider,.js-facility-sp-slider').slick('unslick');
            }
        }

    });

    // Smooth Scroll anchor-fix
    $anchorHeight = $('.p-news-anchor').outerHeight();
    $('.js-anchor').on('click', function (e) {
        e.preventDefault();
        var target = this.hash;
        var $target = $(target);
        $('html, body').stop().animate({
            'scrollTop': $target.offset().top - ($anchorHeight * 2)
        }, 900);
    });

    //Smooth scrolling from one page to a specific section in self page and another page
    setTimeout(function () {
        if (location.hash) {
            window.scrollTo(0, 0);
            target = location.hash.split('#');
            smoothScrollTo($('#' + target[1]));
        }
    }, 1);

    $('.js-nav a').click(function () {
        var myWindowWidth = $(window).width() + 16;
        if (myWindowWidth < 767) {
            $('.js-menu-toggle').removeClass('active');
            $('.c-nav--header').fadeOut();
        }

        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
            location.hostname == this.hostname) {
            smoothScrollTo($(this.hash));
            return false;
        }
    });

    function smoothScrollTo(target) {
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
            $('html,body').animate({
                scrollTop: target.offset().top
            }, 900);
        }
    }

    // Anchor Fixed
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        if (scroll > $anchorHeight) {
            $(".js-news-anchor").addClass("js-anchor-fix");
        }
        else
            $(".js-news-anchor").removeClass("js-anchor-fix");
    });

    //Tab
    $('.js-tabs-nav a').click(function () {
        // Check for active
        $('.js-tabs-nav li').removeClass('is-active');
        $(this).parent().addClass('is-active');

        // Display active tab
        let currentTab = $(this).attr('href');
        $('.c-topics__table-area').hide();
        $(currentTab).show();

        return false;
    });

    // Top Page
    $(".js-page-top").click(function () {
        $('html,body').animate({
            scrollTop: 0
        }, 1000);
    });

    // Sidebar Toggle
    $(".js-toggle-slide").hide();

    $(".js-toggle").click(function (event) {
        $(this).toggleClass("js-active");
        $(this).next("ol.js-toggle-slide").slideToggle();
        event.preventDefault();
    });

    // Accordion
    $(".js-accor .js-toggle:first").addClass("active");
    $(".js-accor .js-content:not(:first)").css("display", "none");
    $(".js-hojin-accor .js-toggle").click(function () {
        $(this).toggleClass("active").next().slideToggle(300);
    });

    $(".js-hojin-accor .js-toggle:nth-of-type(2)").addClass("active");
    $(".js-hojin-accor .js-content:not(:nth-of-type(2))").css("display", "none");

    $(".js-accor .js-toggle").click(function () {
        $(this).toggleClass("active").next().slideToggle(300);
    });

    $(".js-info-accor .js-info-toggle").click(function () {
        $(this).toggleClass("active").next().slideToggle(300);
    });

    // Top Sider
    $('.js-mv-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: true,
        infinite: true,
        cssEase: 'linear',
        autoplaySpeed: 6000,
        centerMode: true,
        centerPadding: 0,
        dots: true,
        responsive: [
            {
                breakpoint: 767.9,
                settings: {
                    arrows: false,
                    variableWidth: true,
                },
            },
        ],
    });

    //Common Slider
    $('.js-cmn-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: true,
        infinite: true,
        cssEase: 'linear',
        autoplaySpeed: 2000,
        variableWidth: true,
        centerMode: true,
        centerPadding: 0,
        dots: true
    });

    //shortstay Slider

    $('.js-shortstay-slider').slick({
        // autoplay: true,
        slidesToShow: 2,
        arrows: true,
        infinite: true,
        cssEase: 'linear',
        autoplaySpeed: 2000,
        variableWidth: true,
        dots: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    variableWidth: true,
                    centerMode: true,
                    centerPadding: 0,
                }
            }
        ]
    });

    // word count ellipsis
    $(function () {
        var max = 35;
        var tot, str;
        $('.js-textTrim35').each(function () {
            str = String($(this).text().replace(/^\s+|\s+|\n+$/g, ' ').trim());
            tot = str.length;
            str = (tot <= max)
                ? str
                : str.substring(0, max) + "...";
            $(this).html(str);
        });

        var l_max = 10;
        $('.js-textTrim10').each(function () {
            str = String($(this).text().replace(/^\s+|\s+|\n+$/g, ' ').trim());
            tot = str.length;
            str = (tot <= l_max)
                ? str
                : str.substring(0, l_max) + "...";
            $(this).html(str);
        });

        var f_max = 50;
        $('.js-textTrim50').each(function () {
            str = String($(this).text().replace(/^\s+|\s+|\n+$/g, ' ').trim());
            tot = str.length;
            str = (tot <= f_max)
                ? str
                : str.substring(0, f_max) + "...";
            $(this).html(str);
        });

        var s_max = 17;
        $('.js-textTrim17').each(function () {
            str = String($(this).text().replace(/^\s+|\s+|\n+$/g, ' ').trim());
            tot = str.length;
            str = (tot <= s_max)
                ? str
                : str.substring(0, s_max) + "...";
            $(this).html(str);
        });

        var m_max = 45;
        $('.js-textTrim45').each(function () {
            str = String($(this).text().replace(/^\s+|\s+|\n+$/g, ' ').trim());
            tot = str.length;
            str = (tot <= m_max)
                ? str
                : str.substring(0, m_max) + "...";
            $(this).html(str);
        });
    });

    // Modal Box
    $('.js-modal').on('click', function () {
        var idModal = $(this).data('modal');
        $(idModal).modal({
            show: true
        });
    });

    //Heightline

    $(".js-heightline").heightLine({
        fontSizeCheck: true
    });
    $('.js-lightbox-slider').slick();

    $('.modal').on('shown.bs.modal', function (e) {
        $('.js-lightbox-slider').slick('setPosition');
        $('.c-lightbox-slider-wrap').addClass('open');
    });

    $(".js-lightbox").on('click', function (event) {
        if (jQuery.inArray(event.target, $('.js-lightbox')) != "-1") {
            $(this).fadeOut('slow');
        }
    });

    // Window Match Media  
    if (window.matchMedia('(min-width: 768px)').matches) {
        $(".js-meal-txt").heightLine({
            fontSizeCheck: true
        });
    } else {
        $(".js-meal-txt").heightLine("destroy");
    }
    if (window.matchMedia('(max-width: 767.9px)').matches) {
        //Heightline
        $(".js-slider-item").heightLine({
            fontSizeCheck: true
        });
        $(".js-tabs-nav a").heightLine({
            fontSizeCheck: true
        });

        $('.js-sp-slider').slick({
            autoplay: true,
            variableWidth: true,
            centerMode: true,
            centerPadding: 0,
        });
        $('.js-facility-sp-slider').slick({
            arrows: true,
            autoplay: true,
            centerMode: true,
            centerPadding: 0,
            variableWidth: true,
        });

    } else {
        $('.js-tabs-nav a,.js-slider-item').heightLine("destroy");
        if ($('.js-sp-slider,.js-facility-sp-slider').hasClass('slick-initialized')) {
            $('.js-sp-slider,.js-facility-sp-slider').slick('unslick');
        }
    }

    // Form

    // お問い合わせ項目

    // $("input:checkbox[id='inquiry-2']").attr({'disabled':true});
    // $("input:checkbox[id='inquiry-2'] + span").after('<span class="c-form__remark c-form__remark--orange">※施設見学はコロナ対策期間中のため実施しておりません。</span>');

    // $("input:checkbox[id='inquiry-items-2']").attr({'disabled':true});
    // $("input:checkbox[id='inquiry-items-2'] + span").after('<span class="c-form__remark c-form__remark--blue">※施設見学はコロナ対策期間中のため実施しておりません。</span>');

    // 希望職種
    var job1 = $('.js-job-checkbox .mwform-checkbox-field:nth-child(1)');
    var job2 = $('.js-job-checkbox .mwform-checkbox-field:nth-child(2)');
    var job3 = $('.js-job-checkbox .mwform-checkbox-field:nth-child(3)');
    var job4 = $('.js-job-checkbox .mwform-checkbox-field:nth-child(4)');
    var job5 = $('.js-job-checkbox .mwform-checkbox-field:nth-child(5)');
    var job6 = $('.js-job-checkbox .mwform-checkbox-field:nth-child(6)');
    job5.hide();
    job6.hide();
    $('.js-job-select').change(function () {
        var val = $('.js-job-select option:selected').val();
        if(val == '正社員　ケアマネージャー') {
            job1.hide();
            job2.hide();
            job3.hide();
            job4.hide();
            job5.fadeIn();
            job6.fadeIn();
            $("input:checkbox[id='license-1']").prop('checked', false).change();
            $("input:checkbox[id='license-2']").prop('checked', false).change();
            $("input:checkbox[id='license-3']").prop('checked', false).change();
            $("input:checkbox[id='license-4']").prop('checked', false).change();
        } else {
            job1.fadeIn();
            job2.fadeIn();
            job3.fadeIn();
            job4.fadeIn();
            job5.hide();
            job6.hide();
            $("input:checkbox[id='license-5']").prop('checked', false).change();
            $("input:checkbox[id='license-6']").prop('checked', false).change();
        }
    });
    // 送信ボタン
    var submitBtn = $("button[type='submit'].js-submit");
    submitBtn.click(function () {
        $(this).css('pointer-events', 'none');
        $(this).css('opacity', '0.5');
  })
});

