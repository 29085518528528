/**
 * SCSS Import
 */
 import '../scss/style.scss';

 /**
  * JS Module Import
  */
  import '../js/lib/jquery-3.6.0.min.js'
  import '../js/lib/slick.min.js'
  import '../js/lib/jquery.heightLine.js'
  import '../js/modal.js'
  import '../js/common.js'